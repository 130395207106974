<template>
  <v-row justify="center" no-gutters>
    <v-col>
      <v-dialog v-model="dialog" persistent width="80vh">
        <template v-slot:activator="{ on }">
          <v-icon
            v-if="isEditYardOrder"
            small
            v-on="on"
            class="mr-2"
            color="teal"
            >mdi-pencil
          </v-icon>
          <v-btn
            v-else
            :color="getColorScheme.darkColor"
            small
            dark
            raised
            v-on="on"
          >
            <v-icon class="mr-1" small>mdi-plus</v-icon>
            Yard Order
          </v-btn>
        </template>
        <v-card>
          <v-card-title
            v-if="isEditYardOrder"
            class="title white--text"
            :style="`background-color:${getColorScheme.darkColor};`"
            >Edit Yard Order
          </v-card-title>
          <v-card-title
            v-else
            class="title white--text"
            :style="`background-color:${getColorScheme.darkColor};`"
            >New Yard Order
          </v-card-title>
          <v-card-text>
            <v-form ref="form">
              <v-row no-gutters v-if="errors.length">
                <v-col>
                  <strong>Please correct the following error(s):</strong>
                  <ul class="red--text">
                    <li v-for="(error, i) in errors" :key="i">{{ error }}</li>
                  </ul>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="3">
                  <v-switch
                    v-model="yardOrder.isWarningOnly"
                    label="Is Warning Only"
                    dense
                    outlined
                    :color="getColorScheme.darkColor"
                    v-on:change="displayFineAmt = !displayFineAmt"
                  ></v-switch>
                </v-col>
                <v-col cols="3" class="pt-4 pl-2 pr-2">
                  <v-text-field
                    v-model="yardOrder.orderNumber"
                    label="Order Number"
                    dense
                    outlined
                    :rules="[(v) => !!v || 'Order Number is required.']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6" class="pr-2">
                  <google-autocomplete
                    placeholder=" Location"
                    @place_changed="setYardOrderLocation"
                    class="yardOrder-location"
                    :value="yardOrder.location.address"
                    v-on:change="onLocationChange"
                  >
                  </google-autocomplete>
                </v-col>
                <v-col cols="2" class="pr-2">
                  <v-text-field
                    v-model="yardOrder.locationLot"
                    label="Lot"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="2" class="pl-2 pr-2">
                  <v-text-field
                    v-model="yardOrder.locationBlock"
                    label="Block"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="2" class="pl-2">
                  <v-text-field
                    v-model="yardOrder.locationPlan"
                    label="Plan"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="pb-6">
                  <v-expansion-panels focusable accordion hover>
                    <v-expansion-panel>
                      <v-expansion-panel-header style="font-size: 0.9em;">
                        Register Owner Information
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row no-gutters>
                          <v-col cols="4" class="pt-3 pr-2">
                            <v-text-field
                              v-model="yardOrder.registeredOwnerName"
                              label="Registered Owner Name"
                              dense
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4" class="pt-3 pl-2 pr-2">
                            <v-text-field
                              v-model="yardOrder.registeredOwnerAddress"
                              label="Registered Owner Address"
                              dense
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4" class="pt-3 pl-2">
                            <v-text-field
                              v-model="yardOrder.registeredOwnerEmail"
                              label="Registered Owner Email"
                              dense
                              outlined
                              :rules="emailRules"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="4" class="pr-2">
                  <v-menu
                    ref="menu3"
                    v-model="menu3"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="yardOrder.inspectionDate"
                        dense
                        outlined
                        label="Date of Inspection"
                        append-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="yardOrder.inspectionDate = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="yardOrder.inspectionDate"
                      :color="getColorScheme.darkColor"
                      @input="menu3 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4" class="pl-2 pr-2">
                  <v-menu
                    ref="menu4"
                    v-model="menu4"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="yardOrder.complianceRequiredByDate"
                        dense
                        outlined
                        label="Compliance Required By"
                        append-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="yardOrder.complianceRequiredByDate = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="yardOrder.complianceRequiredByDate"
                      :color="getColorScheme.darkColor"
                      @input="menu4 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="4" class="pr-2">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="yardOrder.dateIssued"
                        dense
                        outlined
                        label="Date of Issue"
                        append-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="yardOrder.dateIssued = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="yardOrder.dateIssued"
                      :color="getColorScheme.darkColor"
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4" class="pl-2 pr-2">
                  <v-combobox
                    v-model="yardOrder.issuedBy"
                    :items="getUserList"
                    item-value="fullname"
                    item-text="fullname"
                    label="Issuer"
                    dense
                    outlined
                  ></v-combobox>
                </v-col>
                <v-col cols="4" class="pl-2">
                  <v-text-field
                    v-model="yardOrder.byLawNumber"
                    label="ByLaw Number"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="8" class="pr-2">
                  <v-select
                    v-model="yardOrder.requirements"
                    :items="getYORequirements"
                    label="Select Requirement(s)"
                    multiple
                    small-chips
                    dense
                    outlined
                    item-value="_id"
                    item-text="description"
                    v-on:change="selectYORequirement($event)"
                    :rules="[
                      (v) => !!v || 'Select Requirement(s) is required.',
                    ]"
                  ></v-select>
                </v-col>
                <v-col
                  cols="4"
                  v-if="displayFineAmt && yardOrderSelected"
                  class="d-flex align-center justify-center"
                  style="font-size: 0.9em;"
                  ><strong>Total Fine Amount: </strong>{{ "$" + totalFineAmt }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="8" class="pr-2 mb-0">
                  <v-textarea
                    v-model="yardOrder.descriptionOfWork"
                    label="Description of Work"
                    dense
                    outlined
                    rows="2"
                  ></v-textarea>
                </v-col>
                <v-col cols="4" class="pl-2">
                  <v-row no-gutters>
                    <v-col>
                      <span class="caption font-weight-bold pa-0">
                        Delivery Service
                      </span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="7" class="d-flex align-center">
                      <v-checkbox
                        dense
                        hide-details
                        class="ma-0 pa-0"
                        :color="getColorScheme.darkColor"
                        :value="deliveryServiceList[0]"
                        v-model="selectedDeliveryService"
                      ></v-checkbox>
                      <span class="caption pr-3">{{
                        deliveryServiceList[0]
                      }}</span>
                    </v-col>
                    <v-col class="d-flex align-center">
                      <v-checkbox
                        dense
                        hide-details
                        class="ma-0 pa-0"
                        :color="getColorScheme.darkColor"
                        :value="deliveryServiceList[1]"
                        v-model="selectedDeliveryService"
                      ></v-checkbox>
                      <span class="caption">{{ deliveryServiceList[1] }}</span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="7" class="d-flex align-center">
                      <v-checkbox
                        dense
                        hide-details
                        class="ma-0 pa-0"
                        :color="getColorScheme.darkColor"
                        :value="deliveryServiceList[2]"
                        v-model="selectedDeliveryService"
                      ></v-checkbox>
                      <span class="caption">{{ deliveryServiceList[2] }}</span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="float-right">
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="isEditYardOrder"
                      small
                      raised
                      color="blue-grey"
                      dark
                      @click="
                        dialog = false;
                        resetEditYardOrder();
                      "
                      >Cancel
                    </v-btn>
                    <v-btn
                      v-else
                      small
                      raised
                      color="blue-grey"
                      dark
                      @click="cancelYardOrder"
                      >Cancel
                    </v-btn>
                    <v-btn
                      v-if="isEditYardOrder"
                      :color="getColorScheme.darkColor"
                      small
                      raised
                      dark
                      @click="editYardOrder"
                      >Save
                    </v-btn>
                    <v-btn
                      v-else
                      :color="getColorScheme.darkColor"
                      small
                      raised
                      dark
                      @click="saveYardOrder"
                      >Submit
                    </v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { gmapApi } from "vue2-google-maps";
import * as commonjson from "../../shared/common-json";

export default {
  name: "create-yard-order",
  props: {
    yardOrderEdit: {
      type: Object,
      required: false,
    },
    isEditYardOrder: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      yardOrder: {},
      dialog: false,
      place: null,
      menu1: "",
      menu2: "",
      menu3: "",
      menu4: "",
      totalFineAmt: 0.0,
      errors: [],
      displayFineAmt: true,
      deliveryServiceList: commonjson.YODeliveryServiceList,
      selectedDeliveryService: [],
      emailRules: [
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
      ],
    };
  },
  async created() {
    if (this.isEditYardOrder) {
      await this.resetEditYardOrder();
    } else {
      this.yardOrder = {
        isWarningOnly: false,
        orderNumber: "",
        location: {},
        locationLot: "",
        locationBlock: "",
        locationPlan: "",
        registeredOwnerName: "",
        registeredOwnerAddress: "",
        registeredOwnerEmail: "",
        inspectionDate: "",
        complianceRequiredByDate: this.$moment(new Date(), "MMM Do YYYY")
          .add(14, "days")
          .toISOString()
          .substr(0, 10),
        deliveryService: "",
        dateIssued: new Date().toISOString().substr(0, 10),
        issuedBy: this.getUser,
        byLawNumber: "",
        requirements: [],
        descriptionOfWork: "",
        orderStatus: this.getYOStatuses.find((status) => status.statusCode == 1)
          .id,
      };
    }
  },
  computed: {
    ...mapGetters([
      "getUserList",
      "getError",
      "getColorScheme",
      "getYORequirements",
      "getYOStatuses",
      "getUser",
    ]),
    google: gmapApi,
    yardOrderSelected: function() {
      return this.totalFineAmt > 0 ? true : false;
    },
  },
  methods: {
    ...mapActions(["addYardOrderAction", "editYardOrderAction"]),
    selectYORequirement: function(e) {
      e.cancelBubble = true;
      let selectedYPRequirementIds = [];
      for (let i = 0; i < e.length; i++) {
        selectedYPRequirementIds.push(e[i]);
      }
      const totalFine = this.getYORequirements.reduce((total, requirement) => {
        if (selectedYPRequirementIds.includes(requirement.id)) {
          total += requirement.fineAmt;
        }
        return total;
      }, 0);
      this.totalFineAmt = totalFine;
    },
    setYardOrderLocation(place) {
      let newObj = {
        position: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        },
        address: place.formatted_address,
      };
      this.yardOrder.location = newObj;
    },
    onLocationChange() {
      if (this.isEditYardOrder) {
        this.yardOrder.location = {};
      }
    },
    cancelYardOrder() {
      this.dialog = false;
      this.$emit("refreshComponent", true);
    },
    resetEditYardOrder() {
      this.yardOrder = {
        id: this.yardOrderEdit.id,
        isWarningOnly: this.yardOrderEdit.isWarningOnly,
        orderNumber: this.yardOrderEdit.orderNumber,
        location: this.yardOrderEdit.location,
        locationLot: this.yardOrderEdit.locationLot,
        locationBlock: this.yardOrderEdit.locationBlock,
        locationPlan: this.yardOrderEdit.locationPlan,
        registeredOwnerName: this.yardOrderEdit.registeredOwnerName,
        registeredOwnerAddress: this.yardOrderEdit.registeredOwnerAddress,
        registeredOwnerEmail: this.yardOrderEdit.registeredOwnerEmail,
        inspectionDate: this.yardOrderEdit.inspectionDate,
        complianceRequiredByDate: this.yardOrderEdit.complianceRequiredByDate,
        dateIssued: this.yardOrderEdit.dateIssued,
        issuedBy: this.yardOrderEdit.issuedBy,
        byLawNumber: this.yardOrderEdit.byLawNumber,
        requirements: this.yardOrderEdit.requirements,
        descriptionOfWork: this.yardOrderEdit.descriptionOfWork,
        statusUpdateDate: this.yardOrderEdit.statusUpdateDate,
      };

      this.totalFineAmt = this.yardOrderEdit.fineAmtForYO;
      this.selectedDeliveryService = this.yardOrderEdit.deliveryService;
    },
    async saveYardOrder() {
      if (!this.isFormValid()) {
        return;
      }
      this.yardOrder.deliveryService = this.selectedDeliveryService;
      this.yardOrder.statusUpdateDate = new Date();
      await this.addYardOrderAction(this.yardOrder);
      //in case of error from strapi server
      if (this.getError != "") {
        this.errors.push(this.getError);
        this.$store.state.error = "";
      } else {
        this.dialog = false;
        this.$emit("refreshComponent", true);
      }
    },
    async editYardOrder() {
      if (!this.isFormValid()) {
        return;
      }
      this.dialog = false;
      this.yardOrder.deliveryService = this.selectedDeliveryService;
      this.yardOrder.statusUpdateDate = new Date();
      await this.editYardOrderAction(this.yardOrder);
      this.$emit("refreshComponent", true);
    },
    isFormValid() {
      let isValid = true;
      this.errors = [];
      if (
        this.yardOrder.orderNumber == undefined ||
        this.yardOrder.orderNumber == "" ||
        this.yardOrder.orderNumber.trim() == ""
      ) {
        this.errors.push("OrderNumber Number is required.");
      }
      if (
        this.yardOrder.location == undefined ||
        this.yardOrder.location.position == undefined ||
        this.yardOrder.location.position == ""
      ) {
        this.errors.push("Location is required.");
      }
      if (
        this.yardOrder.inspectionDate == undefined ||
        this.yardOrder.inspectionDate == ""
      ) {
        this.errors.push("Inspection Date is required.");
      }
      if (
        this.yardOrder.byLawNumber == undefined ||
        this.yardOrder.byLawNumber == "" ||
        this.yardOrder.byLawNumber.trim() == ""
      ) {
        this.errors.push("ByLaw Number is required.");
      }
      if (
        this.yardOrder.dateIssued == undefined ||
        this.yardOrder.dateIssued == ""
      ) {
        this.errors.push("Date of Issue is required.");
      }
      if (this.yardOrder.issuedBy == undefined) {
        this.errors.push("Issuer is required.");
      }

      if (this.errors.length > 0) {
        isValid = false;
      }
      return isValid;
    },
  },
};
</script>
<style scoped>
.yardOrder-location {
  height: 32px;
  width: 100%;
  outline: none;
  border: 1px solid #808b96;
  border-radius: 4px;
  font-size: 0.9em;
  margin-bottom: 1%;
}
</style>
