<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" lg="8" md="8" sm="12">
        <v-container fluid>
          <v-row class="justify-space-between" align="center" no-gutters>
            <v-col cols="5">
              <p class="title font-weight-bold ma-0">Yard Orders</p>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="4" xs="4">
              <div class="float-right">
                <CreateYardOrder
                  :isEditYardOrder="false"
                  @refreshComponent="refreshComponent"
                  :key="createYardOrderKey"
                ></CreateYardOrder>
              </div>
            </v-col>
          </v-row>
        </v-container>

        <v-container fluid mt-0 mb-0 pt-0 pb-0>
          <v-row>
            <v-col cols="12" lg="2" md="2" sm="4" xs="12" class="pa-0 pl-3">
              <v-text-field
                v-model="searchOrderNumber"
                label="Yard Order Number"
                append-icon="search"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="2" md="2" sm="12" xs="12" class="pa-0 pl-3">
              <v-text-field
                v-model="searchYOLocation"
                label="Yard Order Location"
                append-icon="search"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="2" md="2" sm="12" xs="12" class="pa-0 pl-3">
              <v-combobox
                v-model="searchAssignedTo"
                :items="getUserList"
                item-value="fullname"
                item-text="fullname"
                label="Assigned To"
                clearable
                outlined
                dense
                v-on:change="onSearchAssignedTo"
              ></v-combobox>
            </v-col>
          </v-row>
        </v-container>
        <v-container fluid pt-0 pb-0>
          <v-row>
            <v-col
              cols="12"
              lg="2"
              md="2"
              sm="12"
              xs="12"
              class="pa-0 pl-3 subtitle-1 font-weight-bold"
            >
              List of Yard Orders
            </v-col>
            <v-col cols="12" lg="2" md="2" sm="12" xs="12" class="pa-0 pl-3">
              <v-switch
                dense
                v-model="includeClosedYOs"
                class="mt-0 mb-0"
                :color="getColorScheme.darkColor"
                label="Closed Yard Orders"
              ></v-switch>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="pa-0 pl-3">
              <v-row class="justify-space-between" v-if="yoCheckboxSelected">
                <v-col cols="4" class="pa-0 pl-3">
                  <v-combobox
                    v-model="assignTo"
                    :items="getUserList"
                    item-value="fullname"
                    item-text="fullname"
                    label="Assign To"
                    clearable
                    outlined
                    dense
                  ></v-combobox>
                </v-col>
                <v-col cols="8" class="pa-0 pl-3">
                  <v-btn
                    :color="getColorScheme.darkColor"
                    dark
                    small
                    raised
                    v-on:click="
                      updateYOForAssignTo(
                        selectedYONums,
                        assignTo,
                        getUser.fullname,
                        getUser.email
                      )
                    "
                    >Go
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <v-container fluid style="max-height: 68vh" class="overflow-y-auto">
          <v-row no-gutters>
            <v-col>
              <v-expansion-panels
                focusable
                accordion
                hover
                tile
                v-model="openPanelIndex"
              >
                <v-expansion-panel
                  v-for="(yardOrder, i) in filteredYardOrders"
                  :key="i"
                >
                  <v-expansion-panel-header
                    class="pa-0"
                    disable-icon-rotate
                    style="font-size: 0.9em;"
                  >
                    <template>
                      <v-row align="center" no-gutters>
                        <v-col cols="12" lg="3" md="3" sm="3" xs="12">
                          <v-layout align-center justify-left>
                            <v-checkbox
                              dense
                              hide-details
                              class="mt-0 mb-1 ml-3"
                              :color="getColorScheme.darkColor"
                              :value="yardOrder.id"
                              v-model="selectedYONums"
                              @click.native="selectYardOrder($event)"
                            ></v-checkbox>
                            <Strong>Order #:</Strong>
                            {{ yardOrder.orderNumber }}
                          </v-layout>
                        </v-col>
                        <v-col
                          class="pl-6"
                          cols="12"
                          lg="4"
                          md="4"
                          sm="4"
                          xs="12"
                        >
                          <Strong>Date Issued:</Strong>
                          {{
                            $moment(yardOrder.dateIssued).format("MMM Do YYYY")
                          }}, {{ yardOrder.timeIssued }}
                        </v-col>
                        <v-col
                          class="pl-6"
                          cols="12"
                          lg="2"
                          md="2"
                          sm="2"
                          xs="12"
                        >
                          <v-icon
                            :color="getWarningColor(yardOrder.isWarningOnly)"
                          >
                            info
                          </v-icon>
                          <span class="pl-1" v-if="yardOrder.isWarningOnly">
                            Warning
                          </span>
                          <span class="pl-1" v-else>Yard Order</span>
                        </v-col>
                        <v-col
                          class="pl-6"
                          cols="12"
                          lg="2"
                          md="2"
                          sm="2"
                          xs="12"
                        >
                          <v-icon
                            :color="
                              getStatusColor(yardOrder.orderStatus.statusCode)
                            "
                            >info
                          </v-icon>
                        </v-col>
                        <v-col cols="12" lg="1" md="1" sm="1" xs="12">
                          <v-row v-if="yardOrder.orderStatus.statusCode != 4">
                            <v-col cols="2">
                              <CreateYardOrder
                                :yardOrderEdit="yardOrder"
                                :isEditYardOrder="true"
                                :key="editYardOrderKey"
                              >
                              </CreateYardOrder>
                            </v-col>
                            <v-col cols="2">
                              <v-icon
                                class="mr-2"
                                color="pink"
                                small
                                @click.native="
                                  confirmDeleteYardOrder(
                                    $event,
                                    yardOrder._id,
                                    yardOrder.orderNumber
                                  )
                                "
                                >mdi-delete
                              </v-icon>
                            </v-col>
                            <v-col cols="2">
                              <v-icon
                                v-if="getUser.role.type == 'admin'"
                                class="mr-2"
                                color="purple"
                                small
                                @click.native="
                                  confirmCloseYO($event, yardOrder)
                                "
                                >mdi-close-circle
                              </v-icon>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row dense>
                      <v-col class="pl-0 pb-0">
                        <v-card
                          class="mx-auto"
                          :color="getColorScheme.lightColor"
                          height="100%"
                        >
                          <v-card-text class="pb-0" style="font-size: 0.8em;">
                            <v-row no-gutters>
                              <v-col cols="7">
                                <v-row no-gutters>
                                  <v-col cols="3">
                                    <b>Location:</b>
                                  </v-col>
                                  <v-col cols="9">
                                    {{ yardOrder.location.address }}
                                  </v-col>
                                </v-row>
                                <v-row no-gutters>
                                  <v-col cols="3">
                                    <b>Location Lot:</b>
                                  </v-col>
                                  <v-col cols="9">
                                    {{ yardOrder.locationLot }}
                                  </v-col>
                                </v-row>
                                <v-row no-gutters>
                                  <v-col cols="3">
                                    <b>Location Block:</b>
                                  </v-col>
                                  <v-col cols="9">
                                    {{ yardOrder.locationBlock }}
                                  </v-col>
                                </v-row>
                                <v-row no-gutters>
                                  <v-col cols="3">
                                    <b>Location Plan:</b>
                                  </v-col>
                                  <v-col cols="9">
                                    {{ yardOrder.locationPlan }}
                                  </v-col>
                                </v-row>
                                <v-row no-gutters>
                                  <v-col cols="3">
                                    <b>Registered Owner Name:</b>
                                  </v-col>
                                  <v-col cols="9">
                                    {{ yardOrder.registeredOwnerName }}
                                  </v-col>
                                </v-row>
                                <v-row no-gutters>
                                  <v-col cols="3">
                                    <b>Registered Owner Address:</b>
                                  </v-col>
                                  <v-col cols="9">
                                    {{ yardOrder.registeredOwnerAddress }}
                                  </v-col>
                                </v-row>
                                <v-row no-gutters>
                                  <v-col cols="3">
                                    <b>Registered Owner Email:</b>
                                  </v-col>
                                  <v-col cols="9">
                                    {{ yardOrder.registeredOwnerEmail }}
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="5">
                                <v-row no-gutters v-if="errorYOPhotos">
                                  <p class="error subtitle-2 font-weight-bold">
                                    {{ errorYOPhotos }}
                                  </p>
                                </v-row>
                                <v-row no-gutters>
                                  <v-col cols="2">
                                    <b>Photo(s):</b>
                                  </v-col>
                                  <v-col cols="8">
                                    <v-file-input
                                      label="Upload Image(s) ..."
                                      v-model="yoPhotos"
                                      outlined
                                      dense
                                      small-chips
                                      show-size
                                      multiple
                                    ></v-file-input>
                                  </v-col>
                                  <v-col cols="2" class="pl-1">
                                    <v-btn
                                      :color="getColorScheme.darkColor"
                                      dark
                                      small
                                      raised
                                      v-on:click="uploadYOPhotos(yardOrder)"
                                      >Save
                                    </v-btn>
                                  </v-col>
                                </v-row>
                                <v-row no-gutters>
                                  <v-col>
                                    <v-data-table
                                      dense
                                      :headers="headerPhotos"
                                      :items="yardOrder.photos"
                                      :items-per-page="5"
                                      :sort-by="['createdAt']"
                                      :sort-desc="[true]"
                                      class="elevation-1"
                                      v-if="yardOrder.photos.length > 0"
                                      :style="
                                        `background-color:${getColorScheme.lightColor};`
                                      "
                                    >
                                      <template
                                        v-slot:[`item.createdAt`]="{ item }"
                                      >
                                        <v-row no-gutters align="center">
                                          <v-col class="caption" align="left">
                                            {{
                                              $moment(item.createdAt).format(
                                                dateTimeFormat
                                              )
                                            }}
                                          </v-col>
                                        </v-row>
                                      </template>
                                      <template
                                        v-slot:[`item.photo`]="{ item }"
                                      >
                                        <v-row no-gutters align="center">
                                          <v-col class="caption" cols="2">
                                            <v-icon
                                              color="primary"
                                              @click.native="showPhoto(item)"
                                              >mdi-camera-image
                                            </v-icon>
                                          </v-col>
                                          <v-col
                                            cols="8"
                                            class="caption"
                                            align="left"
                                          >
                                            {{ item.name }}
                                          </v-col>
                                          <v-col
                                            cols="2"
                                            align="right"
                                            v-if="getUser.role.type == 'admin'"
                                          >
                                            <v-btn icon>
                                              <v-icon
                                                color="pink"
                                                small
                                                @click="
                                                  confirmDeletePhoto(
                                                    $event,
                                                    item
                                                  )
                                                "
                                                >mdi-delete
                                              </v-icon>
                                            </v-btn>
                                          </v-col>
                                        </v-row>
                                      </template>
                                    </v-data-table>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-divider></v-divider>
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col cols="12" lg="4" md="4" sm="12" xs="12">
                                <v-row no-gutters>
                                  <v-col cols="5">
                                    <b>Inspection Date:</b>
                                  </v-col>
                                  <v-col cols="7">
                                    {{ yardOrder.inspectionDate }}
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="12" lg="4" md="4" sm="12" xs="12">
                                <v-row no-gutters>
                                  <v-col cols="5">
                                    <b>Compliance Date:</b>
                                  </v-col>
                                  <v-col cols="7">
                                    {{ yardOrder.complianceRequiredByDate }}
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="12" lg="4" md="4" sm="12" xs="12">
                                <v-row no-gutters>
                                  <v-col cols="5">
                                    <b>Delivery Service:</b>
                                  </v-col>
                                  <v-col cols="7">
                                    {{ yardOrder.deliveryService.toString() }}
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col cols="12" lg="4" md="4" sm="12" xs="12">
                                <v-row no-gutters>
                                  <v-col cols="5">
                                    <b>Issuer:</b>
                                  </v-col>
                                  <v-col cols="7">
                                    {{ yardOrder.issuedBy.fullname }}
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="12" lg="4" md="4" sm="12" xs="12">
                                <v-row no-gutters>
                                  <v-col cols="5">
                                    <b>ByLaw Number:</b>
                                  </v-col>
                                  <v-col cols="7">
                                    {{ yardOrder.byLawNumber }}
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="12" lg="4" md="4" sm="12" xs="12">
                                <v-row no-gutters>
                                  <v-col cols="5">
                                    <b>Description of Work:</b>
                                  </v-col>
                                  <v-col cols="7">
                                    {{ yardOrder.descriptionOfWork }}
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-divider></v-divider>
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col cols="12" lg="8" md="8" sm="12" xs="12">
                                <v-row no-gutters>
                                  <v-col cols="2">
                                    <b>Requirements:</b>
                                  </v-col>
                                  <v-col cols="10" class="pl-7">
                                    <p
                                      class="pa-0 ma-0"
                                      v-for="(descriptions,
                                      i) in yardOrder.requirements.map(
                                        (req) => req.description
                                      )"
                                      :key="i"
                                    >
                                      {{ i + 1 + ") " }}{{ descriptions }}
                                    </p>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col
                                cols="12"
                                lg="4"
                                md="4"
                                sm="12"
                                xs="12"
                                v-if="!yardOrder.isWarningOnly"
                              >
                                <v-row no-gutters>
                                  <v-col cols="5">
                                    <b>Fine Amount:</b>
                                  </v-col>
                                  <v-col cols="7">
                                    {{ "$" + getFineAmt(yardOrder) }}
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                            <v-row v-if="yardOrder.assignedTo">
                              <v-col>
                                <v-divider></v-divider>
                              </v-col>
                            </v-row>
                            <v-row no-gutters v-if="yardOrder.assignedTo">
                              <v-col cols="12" lg="4" md="4" sm="12" xs="12">
                                <v-row no-gutters>
                                  <v-col cols="5">
                                    <b>Assigned Date:</b>
                                  </v-col>
                                  <v-col cols="7">
                                    {{
                                      $moment(yardOrder.assignedDate).format(
                                        dateTimeFormat
                                      )
                                    }}
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="12" lg="4" md="4" sm="12" xs="12">
                                <v-row no-gutters>
                                  <v-col cols="5">
                                    <b>Assigned To:</b>
                                  </v-col>
                                  <v-col cols="7">
                                    {{ yardOrder.assignedTo.fullname }}
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-dialog v-model="dialogDeleteConfirm" persistent max-width="425">
            <v-card>
              <v-card-title class="headline">Delete Yard Order?</v-card-title>
              <v-card-text>
                Are you sure you want to delete yard order #{{
                  deleteOrderNum
                }}?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue-grey"
                  raised
                  dark
                  small
                  @click="dialogDeleteConfirm = false"
                  >Cancel
                </v-btn>
                <v-btn
                  :color="getColorScheme.darkColor"
                  dark
                  small
                  raised
                  @click="deleteYardOrder(deleteOrderId)"
                  >Confirm
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogCloseConfirm"
            v-if="closeYardOrderObj"
            persistent
            max-width="425"
          >
            <v-card>
              <v-card-title class="headline">Close Yard Order?</v-card-title>
              <v-card-text>
                Are you sure you want to close yard order #{{
                  closeYardOrderObj.orderNumber
                }}?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue-grey"
                  dark
                  small
                  raised
                  @click="dialogCloseConfirm = false"
                  >Cancel
                </v-btn>
                <v-btn
                  :color="getColorScheme.darkColor"
                  dark
                  small
                  raised
                  @click="closeYardOrder(closeYardOrderObj)"
                  >Confirm
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-col>
      <v-col cols="12" lg="4" sm="12" md="4">
        <MapComponent
          :dataObj="filteredYardOrders"
          titleField="orderNumber"
          statusField="orderStatus"
          locationField="location"
          @setOpenPanelIndex="setOpenPanelIndex"
        ></MapComponent>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogPhoto" width="65%">
      <v-card justify="center">
        <v-card-title class="ma-0 pa-0">
          {{ selectedPhoto.name }}
          <v-spacer></v-spacer>
          <v-btn
            :color="getColorScheme.darkColor"
            dark
            small
            raised
            @click="dialogPhoto = false"
            >Close
          </v-btn>
        </v-card-title>
        <v-card-text class="ma-0 pa-0">
          <v-img :src="`${selectedPhoto.url}`" width="100%" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeletePhotoConfirm" persistent max-width="425">
      <v-card>
        <v-card-title class="headline">Delete Photo?</v-card-title>
        <v-card-text>
          Are you sure you want to delete photo :
          {{ deletePhotoObj.name }} ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-grey"
            dark
            small
            raised
            @click="dialogDeletePhotoConfirm = false"
            >Cancel
          </v-btn>
          <v-btn
            :color="getColorScheme.darkColor"
            dark
            small
            raised
            @click="deletePhoto()"
            >Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import CreateYardOrder from "../yard/create-yard-order";
import { mapActions, mapState, mapGetters } from "vuex";
import * as constants from "../../shared/constants";
import MapComponent from "../map/map-component";

export default {
  name: "yardOrders",
  components: { CreateYardOrder, MapComponent },
  props: {
    openYardOrderId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      dialog: false,
      searchOrderNumber: "",
      searchYOLocation: "",
      searchAssignedTo: "",
      assignTo: "",
      selectedYONums: [],
      dateTimeFormat: constants.DATE_TIME_FORMAT,
      dialogDeleteConfirm: false,
      deleteOrderId: null,
      deleteOrderNum: null,
      closeYardOrderObj: null,
      openPanelIndex: -1,
      createYardOrderKey: 1,
      editYardOrderKey: 1,
      includeClosedYOs: false,
      yoPhotos: null,
      errorYOPhotos: "",
      dialogPhoto: false,
      dialogDeletePhotoConfirm: false,
      selectedPhoto: {},
      deletePhotoObj: {},
      headerPhotos: [
        {
          text: "Date/Time",
          value: "createdAt",
          align: "center",
          sortable: false,
          width: "40%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Photo",
          value: "photo",
          align: "center",
          sortable: false,
          width: "60%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
      ],
    };
  },
  async created() {
    await this.getYardOrdersAction();
    // In case coming from global search
    if (this.openYardOrderId != undefined) {
      this.openPanelIndex = this.yardOrders.findIndex(
        (yardorder) => yardorder.id == this.openYardOrderId
      );
      this.includeClosedYOs = true;
    }
    await this.getYORequirementsAction();
    await this.getYOStatusesAction();
  },
  methods: {
    ...mapActions([
      "getYardOrdersAction",
      "getYORequirementsAction",
      "getYOStatusesAction",
      "updateYOForAssignToAction",
      "deleteYardOrderAction",
      "closeYardOrderAction",
      "updateYOPhotosAction",
      "deleteYOPhotoAction",
    ]),
    getStatusColor(statusCode) {
      if (statusCode == 1) {
        return "red";
      } else if (statusCode == 2) {
        return "orange";
      } else if (statusCode == 3) {
        return "green";
      } else if (statusCode == 4) {
        return "purple";
      }
    },
    getWarningColor(isWarningOnly) {
      if (isWarningOnly) {
        return "amber";
      } else {
        return "red";
      }
    },
    onSearchAssignedTo(assignee) {
      if (assignee != null) {
        this.searchAssignedTo = assignee;
      } else {
        this.searchAssignedTo = "";
      }
    },
    selectYardOrder: function(e) {
      e.cancelBubble = true;
    },
    confirmDeleteYardOrder: function(e, deleteOrderId, deleteOrderNum) {
      e.cancelBubble = true;
      this.dialogDeleteConfirm = true;
      this.deleteOrderId = deleteOrderId;
      this.deleteOrderNum = deleteOrderNum;
    },
    async deleteYardOrder(deleteOrderId) {
      this.dialogDeleteConfirm = false;
      await this.deleteYardOrderAction(deleteOrderId);
    },
    confirmCloseYO: function(e, yardorder) {
      e.cancelBubble = true;
      this.dialogCloseConfirm = true;
      this.closeYardOrderObj = yardorder;
    },
    async closeYardOrder(yardorder) {
      this.dialogCloseConfirm = false;
      let dataObj = {
        yardOrderId: yardorder.id,
        yardOrderNum: yardorder.orderNumber,
        registeredOwnerEmail: yardorder.registeredOwnerEmail,
      };
      if (yardorder.orderStatus.statusCode > 1) {
        dataObj.assignedToEmail = yardorder.assignedTo.email;
      }
      await this.closeYardOrderAction(dataObj);
    },
    refreshComponent: function() {
      this.createYardOrderKey++;
      this.editYardOrderKey++;
    },
    getFineAmt(yardOrder) {
      let totFineAmt = 0.0;
      yardOrder.requirements.forEach(function(entry) {
        totFineAmt += entry.fineAmt;
      });
      yardOrder.fineAmtForYO = totFineAmt;
      return totFineAmt;
    },
    setOpenPanelIndex(openPanelIndex) {
      this.openPanelIndex = openPanelIndex;
    },
    async updateYOForAssignTo(
      selectedYONums,
      assignTo,
      getUserName,
      getUserEmail
    ) {
      if (!assignTo || selectedYONums.length == 0) {
        this.assignTo = "";
        return;
      }
      let dataObj = {
        yardOrderIds: selectedYONums,
        assignedTo: assignTo._id,
        assignedToEmail: assignTo.email,
        assignedToName: assignTo.fullname,
        assignedByName: getUserName,
        assignedByEmail: getUserEmail,
      };
      await this.updateYOForAssignToAction(dataObj);
      this.assignTo = "";
    },
    async uploadYOPhotos(yardOrder) {
      if (this.yoPhotos == null) {
        return;
      }
      if (this.yoPhotos.size > 1024 * 1024 * 5) {
        this.errorYOPhotos = "File too big (> 5MB)";
        return;
      } else {
        this.errorYOPhotos = "";
      }

      let dataObj = {
        yardOrderId: yardOrder.id,
        files: this.yoPhotos,
      };
      await this.updateYOPhotosAction(dataObj);
      this.yoPhotos = null;
    },
    showPhoto(selectedPhoto) {
      this.selectedPhoto = selectedPhoto;
      this.dialogPhoto = true;
    },
    confirmDeletePhoto(e, deletePhotoObj) {
      e.cancelBubble = true;
      this.dialogDeletePhotoConfirm = true;
      this.deletePhotoObj = deletePhotoObj;
    },
    deletePhoto() {
      this.dialogDeletePhotoConfirm = false;
      this.deleteYOPhotoAction(this.deletePhotoObj.id);
    },
  },
  computed: {
    ...mapState(["yardOrders"]),
    ...mapGetters(["getUser", "getColorScheme", "getUserList"]),

    yoCheckboxSelected: function() {
      return this.selectedYONums.length > 0 ? true : false;
    },

    filteredYardOrders: function() {
      this.selectedYONums = [];

      let allowedStatus = [1, 2, 3];
      if (this.includeClosedYOs) {
        allowedStatus = [1, 2, 3, 4];
      }

      let filteredYardOrders = this.yardOrders.filter((yardOrder) => {
        if (
          yardOrder != null &&
          yardOrder != undefined &&
          yardOrder.orderNumber
            .toUpperCase()
            .match(this.searchOrderNumber.toUpperCase()) &&
          yardOrder.location.address
            .toUpperCase()
            .match(this.searchYOLocation.toUpperCase()) &&
          allowedStatus.includes(yardOrder.orderStatus.statusCode)
        ) {
          if (this.searchAssignedTo == "") {
            return yardOrder;
          } else if (
            yardOrder.assignedTo != undefined &&
            yardOrder.assignedTo.id == this.searchAssignedTo.id
          ) {
            return yardOrder;
          }
        }
      });
      return filteredYardOrders;
    },
  },
};
</script>
